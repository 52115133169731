
import './App.css';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom"
import Login from './pages/auth/Login';
import Dashboard from './pages/Dashboard';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'


import ProtectedRoutes from './Routes/ProtectedRoutes';
import EmployeeDetails from './pages/employee_details';
import AddEmployeeDetails from './pages/employee_details/AddEmployeeDetails';

import LeaveApplication from './pages/leave_application';

import LeaveApplied from './pages/leaves_applied';
import EditEmployee from './pages/employee_details/EditEmployee';
import Department from './pages/department';
import LeaveDetail from './pages/employee_details/LeaveDetail';
import Salary from './pages/Salary';
import Holidays from './pages/holidays';
import Attendance from './pages/attendance';
import ViewAttendance from './pages/attendance/ViewAttendance';
import PageNotFound from './pages/errors/PageNotFound';
import EditLeaves from './pages/employee_details/EditLeaves';
import ResignedEmployeeDetails from './pages/employee_details/ResignedEmployeeDetails';
import SalaryLogs from './pages/Salary/SalaryLogs';
import Shifts from './pages/shifts';
import Employment from './pages/employment';
import Esic from './pages/Salary/esic';
import ProvidentFund from './pages/Salary/pf';
import ShiftMapping from './pages/shifts/ShiftMapping';
import ShiftRotation from './pages/shifts/ShiftRotation';
import ShiftsSettings from './pages/shifts/settings';
import SingleEmployeeDetail from './pages/employee_details/SingleEmployeeDetail';
import SingleEmployeeSalary from './pages/Salary/EmployeeSalaryDetails/SingleEmployeeSalary';
import EmployeeSalaryDetails from './pages/Salary/EmployeeSalaryDetails';
import BaseSalary from './pages/Salary/BaseSalary';
import ProfessionalTax from './pages/Salary/PT';
import EmployeePromotion from './pages/employee_details/EmployeePromotion';
import LeaveEditHistory from './pages/leaves_applied/LeaveEditHistory';
import ResignEmployee from './pages/employee_details/ResignEmployee';
import WebCheckIns from './pages/webCheckIns';
import HandBook from './pages/HandBooks';
import ShiftDetails from './pages/shifts/ShiftDetails';
import SinglePersonWebcheckin from './pages/webCheckIns/SinglePersonWebcheckin';
import Layout from './components/layout/Layout';
import { GOOGLE_CLIENT_ID } from './config';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PunchIn, PunchLogs } from './pages/punchin';
import PettyCashListing from './pages/PettyCash/PettyCashListing';
import EmployeeLoanListing from './pages/employee_details/loan/EmployeeLoanListing';
import AddLoan from './pages/employee_details/loan/AddLoan';
import EditLoan from './pages/employee_details/loan/EditLoan';
import EmployeeRepaymentLogs from './pages/employee_details/loan/EmployeeRepaymentLogs';
import PettyCashCreate from './pages/PettyCash/PettyCashCreate';
import PettyCashEdit from './pages/PettyCash/PettyCashEdit';
import SalarySlips from './pages/Salary/SalarySlips';

const queryClient = new QueryClient()
function App() {
  return (
    <>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path='/login' exact element={<Login />} />
            <Route element={<ProtectedRoutes />}>
              <Route element={<Layout />}>
                <Route path='/' exact element={<Dashboard />} />
                <Route path='/employee-details' exact element={<EmployeeDetails />} />
                <Route path='/add-employee-details' exact element={<AddEmployeeDetails />} />
                <Route path='/edit-employee-details/:userType/:id' exact element={<EditEmployee />} />
                <Route path='/salary' exact element={<BaseSalary />} />
                <Route path='/shifts' exact element={<Shifts />} />
                <Route path='/employment-management' exact element={<Employment />} />
                <Route path='/public-holidays' exact element={<Holidays />} />
                <Route path='/attendance' exact element={<Attendance />}/>
                <Route path='/web-check-ins' exact element={<WebCheckIns />} />
                <Route path='/hand-book' exact element={<HandBook />} />
                <Route path='/leaves/:id/:username' exact element={<LeaveDetail />} />
                <Route path='/leave-applied' exact element={<LeaveApplied />} />
                <Route path='/provident-fund' exact element={<ProvidentFund />} />
                <Route path='/esic' exact element={<Esic />} />
                <Route path='/professional-tax' exact element={<ProfessionalTax />} />
                <Route path='/salary/logs' exact element={<SalaryLogs />} />
                <Route path='/salary-slips' exact element={<SalarySlips />} />
                <Route path='/employee-salary/:id/:username' exact element={<EmployeeSalaryDetails />} />
                <Route path='/employee-salary-details/:id/:username' exact element={<SingleEmployeeSalary />} />
                <Route path='/employee-details/:id/:username' exact element={<SingleEmployeeDetail />} />
                <Route path='/employee-promotion/:id/:username' exact element={<EmployeePromotion />} />
                <Route path='/view-attendance/:id' exact element={<ViewAttendance />} />
                <Route path='/web-check-ins/:id' exact element={<SinglePersonWebcheckin />} />
                <Route path='/resigned-employee-details' exact element={<ResignedEmployeeDetails />} />
                <Route path='/resign-employee/:id/:username/:type' exact element={<ResignEmployee />} />
                <Route path='/department' exact element={<Department />} />
                <Route path='/leave-application' exact element={<LeaveApplication />} />
                <Route path='/shift-details' exact element={<ShiftDetails />} />
                <Route path='/leaves' exact element={<LeaveDetail />} />
                <Route path='/attendance-punch-in' exact element={<PunchIn />} />
                <Route path='/attendance-punch-in/logs' exact element={<PunchLogs />} />
                <Route path='/resign-employee/:id/:username' exact element={<ResignEmployee />} />
                {/* <Route path='/petty-cash' exact element={<PettyCashListing />} /> */}
                {/* <Route path='/add-petty-cash' exact element={<PettyCashCreate />} /> */}
                {/* <Route path='/edit-petty-cash/:pettyCashId' exact element={<PettyCashEdit />} /> */}
                <Route path='/loan/:id/:username' exact element={<EmployeeLoanListing />} />
                <Route path='/create-loan/:uid/:username' exact element={<AddLoan />} />
                <Route path='/edit-loan/:uid/:username/:loanId' exact element={<EditLoan />} />
                <Route path='/loan-repayment-log/:uid/:username/:loanId' exact element={<EmployeeRepaymentLogs />} />
                <Route path='/edit-leaves/:leaveId' exact element={<EditLeaves/>}/>
                {/* <Route path='/edit-leaves/:leaveId' exact element={<ProtectedRoutes accessTo={'all'}><EditLeaves /></ProtectedRoutes>} /> */}
              </Route>
            </Route>
            <Route path='*' exact element={<PageNotFound />} />
          </Routes>
        </Router>
      </QueryClientProvider>
    </GoogleOAuthProvider>
    </>
  );
}

export default App;




                {/* <Route path='/attendance' exact element={<ProtectedRoutes accessTo={['admin']}><Attendance /></ProtectedRoutes>} />
                
                <Route path='/employe-details' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><EmployeeDetails /></ProtectedRoutes>} />
                <Route path='/employe-details/:id/:username' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><SingleEmployeeDetail /></ProtectedRoutes>} />
                <Route path='/employee-salary-details/:id/:username' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><SingleEmployeeSalary /></ProtectedRoutes>} />
                <Route path='/employee-promotion/:id/:username' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><EmployeePromotion /></ProtectedRoutes>} />
                <Route path='/resign-employee/:id/:username/:type' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><ResignEmployee /></ProtectedRoutes>} />
                <Route path='/resigned-employee-details' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><ResignedEmployeeDetails /></ProtectedRoutes>} />
                <Route path='/salary/logs' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><SalaryLogs /></ProtectedRoutes>} />
                <Route path='/esic' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><Esic /></ProtectedRoutes>} />
                <Route path='/professional-tax' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><ProfessionalTax /></ProtectedRoutes>} />
                <Route path='/provident-fund' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><ProvidentFund /></ProtectedRoutes>} />
                <Route path='/employee-salary/:id/:username' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><EmployeeSalaryDetails /></ProtectedRoutes>} />
                <Route path='/appointment' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><Appointment /></ProtectedRoutes>} />
                <Route path='/leave-applied' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><LeaveApplied /></ProtectedRoutes>} />
                <Route path='/public-holidays' exact element={<ProtectedRoutes accessTo={['admin', 'hr', 'employee', 'consultant']}><Holidays /></ProtectedRoutes>} />
                <Route path='/add-employe-details' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><AddEmployeeDetails /></ProtectedRoutes>} />
                <Route path='/edit-employe-details/:userType/:id' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><EditEmployee /></ProtectedRoutes>} />
                <Route path='/leave-application' exact element={<ProtectedRoutes accessTo={['employee', 'consultant']}><LeaveApplication /></ProtectedRoutes>} />
                <Route path='/leaves' exact element={<ProtectedRoutes accessTo={['employee', 'consultant']}><LeaveDetail /></ProtectedRoutes>} />
                <Route path='/shifts' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><Shifts /></ProtectedRoutes>} />
                <Route path='/employee-shift-mapping' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><ShiftMapping /></ProtectedRoutes>} />
                <Route path='/employment-management' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><Employment /></ProtectedRoutes>} />
                <Route path='/shift-rotation' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><ShiftRotation /></ProtectedRoutes>} />
                <Route path='/shift-settings' exact element={<ProtectedRoutes accessTo={['admin', 'hr']}><ShiftsSettings /></ProtectedRoutes>} />
                <Route path='/shift-details' exact element={<ProtectedRoutes accessTo={['employee', 'consultant']}><ShiftDetails /></ProtectedRoutes>} />



                <Route path='/leaves/:id' exact element={<ProtectedRoutes accessTo={'all'}><LeaveDetail /></ProtectedRoutes>} />
                <Route path='/leaves/leave-edit-history/:id' exact element={<ProtectedRoutes accessTo={'all'}><LeaveEditHistory /></ProtectedRoutes>} />
                <Route path='/add-leave-details' exact element={<ProtectedRoutes accessTo={['employee', 'consultant']}><LeaveApplicationForm /></ProtectedRoutes>} />
                <Route path='/appointment-letter/:id' exact element={<ProtectedRoutes accessTo={'all'}><AppointmentLetter /></ProtectedRoutes>} />
                <Route path='/open-leave' exact element={<ProtectedRoutes accessTo={'all'}><LeavesApplied /></ProtectedRoutes>} />
                <Route path='/department' exact element={<ProtectedRoutes accessTo={'all'}><Department /></ProtectedRoutes>} />
                <Route path='/view-attendance/:id' exact element={<ProtectedRoutes accessTo={'all'}><ViewAttendance /></ProtectedRoutes>} />
                <Route path='/department' exact element={<ProtectedRoutes accessTo={'all'}><Department /></ProtectedRoutes>} />
                <Route path='/web-check-ins' exact element={<ProtectedRoutes accessTo={'all'}><WebCheckIns /></ProtectedRoutes>} />
                <Route path='/web-check-ins/:id' exact element={<ProtectedRoutes accessTo={'all'}><SinglePersonWebcheckin /></ProtectedRoutes>} />
                <Route path='/hand-book' exact element={<ProtectedRoutes accessTo={'all'}><HandBook /></ProtectedRoutes>} /> */}
              
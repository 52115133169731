import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const dateObjToTimestamp = (date) => {
  return Math.round(date?.getTime?.() / 1000);
};

export const checkIfEmpty = (value) => {
  return value === null || value === undefined || value === "";
};

export const checkIfNotEmpty = (value) => {
  return (
    value !== null && value !== undefined && value !== "" && value !== "null"
  );
};



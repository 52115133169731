import React, { useLayoutEffect, useState } from 'react'
import Layout from '../../components/layout/Layout'
import DatePicker from "react-datepicker";
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import usePut from '../../hooks/put/usePut';
import { handleTotalLeaveCalculation } from '../../utility';
import Spinner from '../../components/loader/Spinner';

const EditLeaves = () => {
    const { state } = useLocation()
    const { leaveId } = useParams()
    const initialState = {
        id: leaveId,
        leave_date_from: '',
        leave_date_to: '',
        action: '',
        comment: '',
        total_leave: '',
    }
    const [form, setForm] = useState(initialState)
    useLayoutEffect(() => {
        let data = {
            ...state,
            leave_date_from: new Date(state.from_date * 1000),
            leave_date_to: new Date(state.to_date * 1000),
            action: state.leave_status === '1' ? 'APPROVE' : 'REJECT',
            total_leave: +state.total_leave
        }
        setForm(data)
    }, [])
    const navigate = useNavigate();
    const handlePushBack = () => {
        navigate(-1)
    }
    const handleSetForm = ({ value, name }) => {
        if (name === 'leave_date_from') {
            setForm({
                ...form,
                [name]: value,
                leave_date_to: value > form.leave_date_to ? value : form.leave_date_to,
                total_leave: value > form.leave_date_to ? 1 : handleTotalLeaveCalculation(value, form.leave_date_to),
            });
        } else if (name === 'leave_date_to') {
            setForm({
                ...form,
                [name]: value,
                total_leave: handleTotalLeaveCalculation(form.leave_date_from, value)
            });
        } else {
            setForm({ ...form, [name]: value });
        }
    }
    const { handleAuth } = usePut({
        url: 'editapprovedleave',
        onSuccess:(res) => {
            alert(res.data.message)
            handlePushBack()
        },
        onError:(err) => {
            alert(err.response.data.message);
        }
    })
    const handleSubmitLeaveInformation = (e) => {
        e.preventDefault()
        handleAuth.mutate(form)
        // console.log(form);
        
    }
    return (
        // <Layout>
        <div>


            {handleAuth.isLoading && <Spinner fullWidth={true}/>}
            <div className='px-5'>
                <div className=' relative'>
                    {/* <div className=' absolute left-5 top-1/2 -translate-y-1/2 cursor-pointer' onClick={handlePushBack}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                    </div> */}
                    <h1 className='text-2xl text-center text-gray-600 py-4 border-b border-gray-300'>Applied Leave Information</h1>
                </div>
                <div className='py-4'>
                    <div className=' space-y-2'>
                        <h2 className=' font-semibold text-xl text-gray-700'>Username: {state.name} <span className='text-lg'>{`(${state.user_type})`}</span></h2>
                    </div>
                    <div className=' py-4'>
                        <form className=' space-y-4' onSubmit={handleSubmitLeaveInformation}>
                            <div className='grid grid-cols-3 gap-4'>
                                <div className="">
                                    <label htmlFor="from date" className='label'>From Date:</label>
                                    <DatePicker selected={form.leave_date_from} className='input'  onChange={(value) => handleSetForm({ value, name: 'leave_date_from' })} />
                                </div>
                                {/* dateFormat="dd/MM/yyyy" */}
                                <div className="">
                                    <label htmlFor="to date" className='label'>To Date:</label>
                                    <DatePicker selected={form.leave_date_to} className='input' minDate={form?.leave_date_from} onChange={(value) => handleSetForm({ value, name: 'leave_date_to' })} />
                                </div>
                                <div className="">
                                    <label htmlFor="to date" className='label'>Total Leaves:</label>
                                    <input type="text" disabled className='input' defaultValue={form.total_leave}/>
                                </div>
                            </div>
                            <div className="">
                                <label htmlFor="status" className='label'>Status:</label>
                                <select value={form.action} className='input' name="status" onChange={(e) => handleSetForm({ value: e.target.value, name: 'action' })}>
                                    <option value="">--Select Status--</option>
                                    <option value="APPROVE">Approved</option>
                                    <option value="REJECT">Reject</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="status" className='label'>Reason:</label>
                                <textarea name="reason" className=' min-h-[10rem] input' value={form.reason} placeholder='Enter remark' onChange={(e) => handleSetForm({ value: e.target.value, name: 'reason' })}></textarea>
                            </div>
                            <div>
                                <button type='submit' className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Verify Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        // {/* </Layout> */}
         
    )
}

export default EditLeaves
import { useForm } from "react-hook-form"
import { Input } from "../ui"
import { RxReload } from "react-icons/rx";
import useBasicLogin from "../../hooks/auth/useBasicLogin";

const UsernamePassword = () => {
    const { control,handleSubmit } = useForm({
        defaultValues: {
            email: '',
            password: ''
        }
    })
    const {handleLoginUser,loginUser} = useBasicLogin()
    return (
        <div className="w-[69.5%] space-y-4">
            <Input
                control={control}
                label={"Email"}
                name={"email"}
                placeholder={"Please Enter Email"}
                rules={{
                    required: "Email is required",
                }}
            />
            <Input
                control={control}
                label={"Password"}
                type='password'
                name={"password"}
                placeholder={"Please Enter Password"}
                rules={{
                    required: "Password is required",
                }}
            />
            <button onClick={handleSubmit(handleLoginUser)} disabled={loginUser.isLoading} className=" bg-primary w-full rounded-md py-2 text-white">
                <div className=" flex items-center justify-center space-x-3">
                    <span>Login</span>
                    {loginUser.isLoading && <RxReload className=" animate-spin"/>}
                </div>
            </button>
        </div>
    )
}

export default UsernamePassword